import React, { useEffect } from "react";
import {
  Card,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  FormFeedback,
  Tooltip,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { ENDPOINT_URL } from "../../config/app-config";
import {
  BsEye,
  BsFillEyeSlashFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import Swal from "sweetalert2";

const Forget = (args) => {
  const [phone, setPhone] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPass, setCheckPass] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [checkPassConfirm, setCheckPassConfirm] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [Cv, setCV] = useState("");
  const [name, Setname] = useState("");

  useEffect(() => {
    getUsername();
  }, []);

  const getUsername = () => {
    const dataw = localStorage.getItem("userdata");
    setCV(JSON.parse(dataw));
    console.log(Cv);
  };

  const toggle = () => {
    setVisible(!isVisible);
  };

  const toggletooltip = () => setTooltipOpen(!tooltipOpen);
  const handleChange = (event) => {
    const phone = event.target.value;
    setPhone(phone);
    localStorage.setItem("phone_reset", phone);
    if (phone.length === 10 && /[0-9]/.test(phone)) {
      setCheckPhone(false);
      // localStorage.setItem("username", phone);
    } else {
      setCheckPhone(true);
    }
  };

  const handlePass = (event) => {
    const password = event.target.value;
    setPassword(password);
    localStorage.setItem("password_reset", password);
    if (
      password.length >= 8 &&
      password.length <= 12 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      !/[]/.test(password) &&
      password.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)
    ) {
      setCheckPass(false);
    } else {
      setCheckPass(true);
    }
  };
  const handlePassConfirm = (event) => {
    const passwordConfirm = event.target.value;
    setPasswordConfirm(passwordConfirm);
    if (passwordConfirm === password) {
      setCheckPassConfirm(false);
    } else {
      setCheckPassConfirm(true);
    }
  };

  const otpres = async () => {
    let body = {
      phone: phone,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}otprequest-check`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var result = await response.json();
      console.log(result.status);
      if (result.status_code == 200) {
        Swal.fire({
          title: "เบอร์นี้ถูกใช้งานไปแล้ว",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
      } else if ((result.status = "success")) {
        window.location = "/verification";
      }
      localStorage.setItem("token", result.token);
    } catch (err) {
      return err;
    }
  };

  //   const changePwd = async () => {
  //     const jwt = localStorage.getItem("jwt_token");
  //     let body = {
  //       phone: phone,
  //       new_password: password,
  //     };
  //     try {
  //       var response = await fetch(`${ENDPOINT_URL}/changpwd`, {
  //         method: "POST",
  //         headers: {
  //           Accept: "appclication/json",
  //           "Content-Type": "appclication/json",
  //           Authorization: "Bearer " + jwt,
  //         },
  //         body: JSON.stringify(body),
  //       });
  //     } catch (err) {
  //       return err;
  //     }
  //   };
  return (
    <div className="container">
      <Form inline>
        <Container className="d-flex vh-100">
          <Row className="m-auto align-self-center">
            <Col>
              <Card body className="my-2">
                <CardTitle align="center" tag="h2">
                  WorkWith
                </CardTitle>
                <FormGroup>
                  <Label>CV</Label>
                  <Input
                    // invalid={checkPhone}
                    value={Cv.username || ""}
                    placeholder="CV"
                    disabled
                    onChange={(e) => {
                      setCV(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    // invalid={checkPhone}
                    value={name || ""}
                    placeholder="ชื่อร้าน"
                    onChange={(e) => {
                      Setname(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>เบอร์โทรศัพท์</Label>
                  <Input
                    invalid={checkPhone}
                    value={phone || ""}
                    placeholder="Telphone number"
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>กรุณาตั้งรหัสผ่านใหม่</Label>
                  <br />
                  <a style={{ fontSize: 10, color: "red" }}>
                    *รหัสผ่านต้องมีความยาว 8-12 ตัว และ ต้องประกอบด้วย
                    <br />
                    ตัวอักษรพิมพ์เล็ก ตัวอักษรพิมพ์ใหญ่ และตัวเลข <bg />
                  </a>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      name="passwordConfirm"
                      type={!isVisible ? "password" : "text"}
                      placeholder="Password"
                      invalid={checkPass}
                      value={password || ""}
                      onChange={handlePass}
                    />
                    <span
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                      onClick={toggle}
                    >
                      {isVisible ? <BsEye /> : <BsFillEyeSlashFill />}
                    </span>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>ยืนยันรหัสผ่าน</Label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      name="passwordConfirm"
                      type={!isVisible ? "password" : "text"}
                      placeholder="Password"
                      invalid={checkPassConfirm}
                      value={passwordConfirm || ""}
                      onChange={handlePassConfirm}
                    />
                    <span
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                      onClick={toggle}
                    >
                      {isVisible ? <BsEye /> : <BsFillEyeSlashFill />}
                    </span>
                  </div>
                </FormGroup>
                <Button
                  style={{
                    background: "#49C5B1",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  disabled={checkPass ? true : false}
                  onClick={(e) => {
                    console.log("checkpass", checkPass);
                    if (checkPass == true) {
                      Swal.fire({
                        title: "กรุณากรอกรหัสผ่านใหม่",
                        text: "รหัสผ่านจะต้องมีไม่ต่ำกว่า 8 ตัว และต้องไม่เกิน 12 ตัวประกอบไปด้วยตัวอักษรใหญ่อย่างน้อย 1 ตัว และตัวเลข",
                        icon: "error",
                        confirmButtonText: "ตกลง",
                      });
                    } else if (checkPassConfirm == true) {
                      Swal.fire({
                        title: "กรุณากรอกรหัสผ่านใหม่",
                        text: "ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน",
                        icon: "error",
                        confirmButtonText: "ตกลง",
                      });
                    } else {
                      localStorage.setItem("dataregis", Cv.username);
                      localStorage.setItem("datanameregis", name);
                      otpres();
                    }
                  }}
                >
                  ยืนยันรหัสผ่าน
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default Forget;
