import React from "react";
import {
  Card,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { ENDPOINT_URL, ENDPOINT_OTP } from "../../config/app-config";
import Swal from "sweetalert2";
import {
  BsEye,
  BsFillEyeSlashFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import { key, Secret } from "../page/Login/configlogin";

const RegisterPage = (args) => {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [phone, setPhone] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPass, setCheckPass] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [checkPassConfirm, setCheckPassConfirm] = useState(false);
  const [check, setCheck] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [regis, setRegis] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isVisible2, setVisible2] = useState(false);

  const toggletooltip = () => setTooltipOpen(!tooltipOpen);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      setCheckEmail(false);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
      setCheckEmail(true);
    } else {
      setCheckEmail(false);
    }

    return errors;
  };

  // const formik = useFormik({
  //     initialValues: {
  //         email: '',
  //     },
  //     validate,
  //     onSubmit: (values) => {
  //         alert(JSON.stringify(values, null, 2))
  //     },
  // })

  const toggle = () => setModal(!modal);
  const handleChange = (event) => {
    const phone = event.target.value;
    setPhone(phone);
    if (phone.length === 10 && /[0-9]/.test(phone)) {
      setCheckPhone(false);
      localStorage.setItem("phone_reset", phone);
    } else {
      setCheckPhone(true);
    }
  };
  const handlePass = (event) => {
    const password = event.target.value;
    console.log("phone", password.length);
    setPassword(password);
    if (
      password.length >= 8 &&
      password.length <= 12 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      !/[]/.test(password) &&
      password.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)
    ) {
      setCheckPass(false);
    } else {
      setCheckPass(true);
    }
  };
  const handlePassConfirm = (event) => {
    const passwordConfirm = event.target.value;
    setPasswordConfirm(passwordConfirm);
    if (passwordConfirm === password) {
      setCheckPassConfirm(false);
    } else {
      setCheckPassConfirm(true);
    }
  };

  const registerPost = async () => {
    let body = {
      username: phone,
      password: password,
    };
    localStorage.setItem("regisinfo", JSON.stringify(body));
    if (checkPhone === true) {
      Swal.fire({
        title: "Error!",
        text: "เบอร์โทรศัพท์ของท่านไม่ถูกต้อง",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    } else if (checkPass === true) {
      Swal.fire({
        title: "Error!",
        text: "รหัสผ่านต้องมีความยาวได้ 8 - 12 ตัว\nต้องมีตัวอักษร[A-Z] \n [a-z] และ ตัวเลข [0-9] อย่างละหนึ่งตัว",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    } else if (checkPassConfirm === true) {
      Swal.fire({
        title: "Error!",
        text: "รหัสผ่านไม่ถูกต้อง",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    } else {
      try {
        var response = await fetch(`${ENDPOINT_URL}regiswork`, {
          method: "POST",
          headers: {
            Accept: "appclication/json",
            "Content-Type": "appclication/json",
          },
          body: JSON.stringify(body),
        });
        var data = await response.json();
        if (data.code === 200) {
          Swal.fire({
            title: "Success",
            text: "Success",
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then((result) => {
            if (result.isConfirmed) {
              otpres();
              window.location = "/verification";
            }
          });
        } else if (data.code === 409) {
          Swal.fire({
            title: "Error!",
            text: "บัญชีผู้ใช้นี้สมัครเคยสมัครเข้าใช้งานแล้ว กรุณากดลืมรหัสผ่าน หากจำรหัสผ่านของท่านไม่ได้",
            icon: "error",
            confirmButtonText: "ตกลง",
          });
        }
      } catch (err) {
        return err;
      }
    }
  };

  const otpres = async () => {
    let body = {
      phone: phone,
    };
    try {
      var response = await fetch(`${ENDPOINT_URL}otprequest`, {
        method: "POST",
        headers: {
          Accept: "appclication/json",
          "Content-Type": "appclication/json",
        },
        body: JSON.stringify(body),
      });
      var result = await response.json();
      console.log(result.token);
      localStorage.setItem("token", result.token);
    } catch (err) {
      return err;
    }
  };

  const togglepassword = () => {
    setVisible(!isVisible);
  };
  const togglepassword2 = () => {
    setVisible2(!isVisible2);
  };
  return (
    // console.log(password.length),
    <div className="container">
      <Form inline>
        <Container className="d-flex vh-100">
          <Row className="m-auto align-self-center">
            <Col>
              <Card body className="my-2" style={{}}>
                <CardTitle align="center" tag="h2">
                  WorkWith
                </CardTitle>
                {/* <FormGroup>
                            <Label>
                                อีเมล
                            </Label>
                            <Input
                                name="email"
                                placeholder="Email"
                                type="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                invalid={checkEmail} />
                            {formik.touched.email && formik.errors.email && (
                                <span style={{color: 'red'}}>{formik.errors.email}</span>)}
                        </FormGroup> */}
                <FormGroup>
                  <Label>เบอร์โทรศัพท์</Label>
                  <Input
                    name="phone"
                    placeholder="Phone"
                    type="phone"
                    invalid={checkPhone}
                    value={phone || ""}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>รหัสผ่าน</Label>
                  <br />
                  <a style={{ fontSize: 10, color: "red" }}>
                    *รหัสผ่านต้องมีความยาว 8-12 ตัว และ ต้องประกอบด้วย
                    <br />
                    ตัวอักษรพิมพ์เล็ก ตัวอักษรพิมพ์ใหญ่ และตัวเลข <bg />
                  </a>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      name="passwordConfirm"
                      type={!isVisible ? "password" : "text"}
                      placeholder="Password"
                      invalid={checkPass}
                      value={password || ""}
                      onChange={handlePass}
                    />
                    <span
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                      onClick={togglepassword}
                    >
                      {isVisible ? <BsEye /> : <BsFillEyeSlashFill />}
                    </span>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>ยืนยันรหัสผ่าน</Label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      name="passwordConfirm"
                      type={!isVisible2 ? "password" : "text"}
                      placeholder="Password"
                      invalid={checkPassConfirm}
                      value={passwordConfirm || ""}
                      onChange={handlePassConfirm}
                    />
                    <span
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                      onClick={togglepassword2}
                    >
                      {isVisible ? <BsEye /> : <BsFillEyeSlashFill />}
                    </span>
                  </div>
                </FormGroup>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    value={check}
                    onChange={(e) => {
                      setCheck(e.target.checked);
                    }}
                  />
                  <Label check>ฉันยอมรับ</Label>
                  <a
                    className="text-decoration-none"
                    style={{ marginLeft: "5px", color: "#49C5B1" }}
                    onClick={toggle}
                  >
                    privacy policy & terms
                  </a>
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                      Privacy policy & terms
                    </ModalHeader>
                    <ModalBody style={{ overflow: "scroll", height: 500 }}>
                      <p>
                        ประกาศเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล (Privacy
                        Notice) สำหรับกระบวนการที่เกี่ยวข้องกับลูกค้าของบริษัท
                        ไนน์ เวลธิ จำกัด
                      </p>
                      <p>1. บททั่วไป</p>
                      <p>
                        เพื่อเป็นการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                        พ.ศ. 2562 และกฎหมายลำดับรองที่เกี่ยวข้อง
                        และให้หมายความรวมถึงกฎหมายฉบับแก้ไขเพิ่มเติมใด ๆ ในอนาคต
                        (“กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล”)
                        บริษัทในบริษัท ไนน์ เวลธิ จำกัด (“บริษัทฯ”)
                        จึงจัดทำประกาศเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
                        (Privacy Notice)
                        สำหรับกระบวนการที่เกี่ยวข้องกับลูกค้าของบริษัท ไนน์
                        เวลธิ จำกัด (“ประกาศฯ”) ฉบับนี้ขึ้น
                        เพื่ออธิบายให้ท่านทราบถึงวิธีการที่บริษัทฯ
                        ปฏิบัติต่อข้อมูลที่สามารถระบุตัวตนของท่าน
                        หรืออาจจะระบุตัวตนของท่านได้ ไม่ว่าทางตรงหรือทางอ้อม
                        ตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
                        (“ข้อมูลส่วนบุคคล”) เช่น วิธีการเก็บรวบรวม ใช้ เปิดเผย
                        หรือ การดำเนินการใด ๆ
                        กับข้อมูลส่วนบุคคลไม่ว่าด้วยวิธีการอัตโนมัติหรือไม่ก็ตาม
                        อาทิ การบันทึก การจัดระบบ การจัดเก็บ
                        การปรับเปลี่ยนหรือการดัดแปลง การเรียกคืน การส่ง โอน
                        การเผยแพร่หรือการทำให้สามารถเข้าถึงหรือพร้อมใช้งานโดยวิธีใด
                        ๆ การจัดเรียง การนำมารวมกัน การจำกัดหรือการห้ามเข้าถึง
                        การลบหรือการทำลาย (“ประมวลผล”)
                        และเพื่อแจ้งให้ท่านทราบถึงวัตถุประสงค์ในการประมวลผล
                        ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
                        ตลอดจนสิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล ทั้งนี้
                        บริษัทฯ ขอแนะนำให้ท่านอ่านและทำความเข้าใจถึงข้อกำหนดต่าง
                        ๆ ภายใต้ประกาศฯ โดยมีรายละเอียดดังต่อไปนี้
                      </p>
                      <p>
                        2. กลุ่มหรือประเภทของบุคคลที่บริษัทฯ
                        ทำการเก็บรวบรวมข้อมูลส่วนบุคคล ภายใต้ประกาศฯ ฉบับนี้
                        กลุ่มหรือประเภทของบุคคลที่บริษัทฯ
                        ทำการเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลประกอบด้วย
                      </p>
                      <p>
                        2.1 ลูกค้า ซึ่งหมายถึง
                        บุคคลที่ซื้อสินค้าและ/หรือใช้บริการจากบริษัทฯ
                        และ/หรืออาจจะซื้อสินค้าและ/หรือใช้บริการจากบริษัทฯ
                        หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน อาทิ
                        ผู้เข้าร่วมกิจกรรม ผู้เข้าร่วมสัมมนา
                        ผู้เข้าร่วมการประมูล ผู้ใช้บริการเว็บไซต์
                        ผู้ใช้บริการแอปพลิเคชัน
                        ผู้ที่ติดต่อเพื่อขอรับข้อมูลหรือขอรับบริการจากบริษัทฯ
                        และผู้ตอบแบบสอบถามเกี่ยวกับสินค้าและ/หรือบริการของบริษัทฯ
                        เป็นต้น
                      </p>
                      <p>
                        2.2 ผู้ที่เกี่ยวข้องกับลูกค้า ซึ่งหมายถึง
                        บุคคลที่เกี่ยวข้องหรือเป็นตัวแทนของลูกค้า เช่น ผู้บริหาร
                        กรรมการ พนักงาน ตัวแทน หรือบุคลากรใด ๆ
                        ของลูกค้าซึ่งเป็นนิติบุคคล
                        และให้หมายความรวมถึงผู้ที่ข้อมูลส่วนบุคคลปรากฏในเอกสารต่าง
                        ๆ ที่เกี่ยวข้องกับกระบวนการที่เกี่ยวข้อง อาทิ ผู้จัดการ
                        ผู้สั่งซื้อ ผู้รับสินค้า และผู้สั่งจ่ายเช็ค เป็นต้น
                      </p>
                      <p>
                        3. วิธีที่บริษัทฯ เก็บรวบรวมและรับข้อมูลส่วนบุคคลของท่าน
                      </p>
                      <p>
                        3.1 บริษัทฯ
                        เก็บรวบรวมและรับข้อมูลส่วนบุคคลของท่านผ่านช่องทางต่าง ๆ
                        ดังนี้
                      </p>
                      <p>
                        (ก)ข้อมูลส่วนบุคคลที่ท่านให้ไว้กับบริษัทฯ
                        โดยตรงท่านอาจให้ข้อมูลส่วนบุคคลแก่บริษัทฯ โดยตรง เช่น
                      </p>
                      <p>
                        เมื่อท่านติดต่อสื่อสาร สอบถามข้อมูล
                        ให้ความเห็นหรือคำติชมแก่บริษัทฯ
                        ไม่ว่าจะเป็นการสื่อสารเป็นในรูปแบบลายลักษณ์อักษรหรือวาจา
                        ผ่านทางเว็บไซต์ แอปพลิเคชัน โทรศัพท์ อีเมล โทรสาร
                        ไปรษณีย์ การพบปะกันโดยตรง หรือโดยวิธีการอื่นใด
                      </p>
                      <p>
                        เมื่อท่านแสดงเจตนาเพื่อซื้อสินค้าหรือใช้บริการจากบริษัทฯ
                        เข้าทำสัญญากับบริษัทฯ หรือส่งมอบเอกสารต่าง ๆ
                        ซึ่งมีข้อมูลส่วนบุคคลปรากฏอยู่มาให้แก่บริษัทฯ
                      </p>
                      <p>
                        เมื่อท่านสมัครสมาชิกผ่านทางเว็บไซต์หรือแอปพลิเคชันที่จัดทำโดยหรือในนามของบริษัทฯ
                        ซึ่งครอบคลุมไปถึง
                        การลงชื่อเข้าใช้ผ่านผู้ให้บริการที่มาจากบุคคลภายนอก และ
                      </p>
                      <p>
                        เมื่อท่านเข้าร่วมกิจกรรมทางการตลาด หรือระบบสะสมคะแนน
                        การทำแบบสำรวจ หรือการแสดงความคิดเห็นต่อสินค้า
                        และ/หรือบริการ การจับสลากชิงโชค งานอีเว้นท์
                        หรือกิจกรรมอื่น ๆ ที่จัดขึ้นโดยหรือในนามของบริษัทฯ
                        เป็นต้น
                      </p>
                      <p>
                        (ข)ข้อมูลส่วนบุคคลที่บริษัทฯ
                        เก็บรวบรวมจากท่านโดยอัตโนมัติบริษัทฯ
                        อาจเก็บรวบรวมข้อมูลส่วนบุคคลของท่านโดยอัตโนมัติผ่านช่องทางต่าง
                        ๆ เช่น การใช้คุกกี้ (Cookies) หรือเทคโนโลยีอื่น ๆ
                        ที่คล้ายคลึงกัน รายละเอียดเพิ่มเติมโปรดดูที่
                        นโยบายการใช้คุกกี้
                      </p>
                      <p>
                        (ค)ข้อมูลส่วนบุคคลที่บริษัทฯ
                        ได้รับมาจากบุคคลภายนอกบริษัทฯ
                        อาจได้รับข้อมูลส่วนบุคคลของท่านมาจากบุคคลภายนอก เช่น
                      </p>
                      <p>
                        การเก็บข้อมูลจากผู้ให้บริการต่าง ๆ ของบริษัทฯ อาทิ
                        ผู้ให้บริการเว็บไซต์ ผู้ให้บริการแอปพลิเคชัน
                        ผู้ให้บริการพื้นที่พาณิชย์อิเล็กทรอนิกส์ (E-Commerce)
                        ผู้ให้บริการศูนย์บริการข้อมูลลูกค้า (Call Center)
                        ผู้ให้บริการประชาสัมพันธ์และแนะนำสินค้า/บริการ
                        และผู้ให้บริการรวบรวมข้อมูล เป็นต้น
                      </p>
                      <p>
                        ในบางกรณีบริษัทฯ
                        อาจเก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากแหล่งข้อมูลสาธารณะ
                        แหล่งข้อมูลเกี่ยวกับธุรกิจของท่าน
                        หรือแหล่งข้อมูลทางการค้า
                        ไม่ว่าท่านจะเป็นผู้เปิดเผยข้อมูลส่วนบุคคลด้วยตนเองหรือได้ให้ความยินยอมแก่ผู้ใดในการเปิดเผยข้อมูลส่วนบุคคลของท่านดังกล่าว
                      </p>
                      <p>
                        3.2 ในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน
                        ท่านจะได้รับการแจ้งถึงรายละเอียดต่าง ๆ
                        ตามที่ระบุในประกาศฯ ฉบับนี้ ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะ
                        วัตถุประสงค์และฐานทางกฎหมายในการเก็บรวบรวม ใช้ เปิดเผย
                        และ/หรือ ประมวลผลข้อมูลส่วนบุคคล
                        หรือหากเป็นกรณีที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนดให้การประมวลผลข้อมูลส่วนบุคคลใดต้องได้รับความยินยอมจากท่าน
                        บริษัทฯ จะขอความยินยอมโดยชัดแจ้งจากท่าน
                      </p>
                      <p>
                        3.3 ในกรณีที่บริษัทฯ
                        เก็บรวบรวมข้อมูลส่วนบุคคลของท่านไว้ก่อนวันที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลมีผลใช้บังคับ
                        บริษัทฯ
                        จะเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของท่านต่อไปตามวัตถุประสงค์เดิมในการเก็บรวบรวมข้อมูลส่วนบุคคล
                        ซึ่งท่านมีสิทธิยกเลิกความยินยอม โดยติดต่อมายังบริษัทฯ
                        ตามรายละเอียดการติดต่อที่ระบุไว้ในข้อ 9 ของประกาศฯ
                        ฉบับนี้ ทั้งนี้ บริษัทฯ
                        ขอสงวนสิทธิในการพิจารณาคำขอยกเลิกความยินยอมของท่านและดำเนินการตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                      </p>
                      <p>4. ข้อมูลส่วนบุคคลที่ถูกเก็บรวบรวม</p>
                      <p>
                        ข้อมูลส่วนบุคคลของท่านที่ถูกเก็บรวบรวมและประมวลผลภายใต้ประกาศฯ
                        ฉบับนี้
                        ไม่ว่าจะเป็นข้อมูลส่วนบุคคลที่ท่านให้ไว้กับบริษัทฯ
                        โดยตรง หรือข้อมูลส่วนบุคคลที่บริษัทฯ
                        เก็บรวบรวมจากท่านโดยอัตโนมัติ
                        หรือข้อมูลส่วนบุคคลที่บริษัทฯ ได้รับมาจากบุคคลภายนอก
                        ได้แก่
                      </p>
                      <p>
                        4.1 ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล ชื่อเล่น
                        วัน/เดือน/ปีเกิด อายุ เพศ หมายเลขบัตรประจำตัวประชาชน
                        รูปถ่าย ลายมือชื่อ เป็นต้น
                      </p>
                      <p>
                        4.2 ข้อมูลการติดต่อ เช่น ที่อยู่ตามบัตรประจำตัวประชาชน
                        ที่อยู่ตามทะเบียนบ้าน สถานที่จัดส่งสินค้า
                        สถานที่จัดส่งใบแจ้งหนี้ หมายเลขโทรศัพท์ โทรสาร อีเมล
                        บัญชีผู้ใช้บริการสังคมออนไลน์ (social media) (อาทิ
                        บัญชีเฟสบุ๊ค (Facebook) บัญชีอินสตาแกรม (Instagram)
                        บัญชีทวิตเตอร์ (Twitter) บัญชีแอปพลิเคชันไลน์ (LINE ID)
                        หรือ ผู้ให้บริการที่มาจากบุคคลภายนอก)
                        และข้อมูลผู้ที่สามารถติดต่อได้ เป็นต้น
                      </p>
                      <p>
                        4.3 ข้อมูลเกี่ยวกับธุรกิจของลูกค้า เช่น
                        รายละเอียดเกี่ยวกับโรงงานของลูกค้า (เช่น ชื่อ เลขทะเบียน
                        สถานที่ตั้ง และข้อมูลการติดต่อ เป็นต้น) เลขทะเบียนรถยนต์
                        และประเภทของรถยนต์ เป็นต้น
                      </p>
                      <p>
                        4.4 ข้อมูลเกี่ยวกับธุรกรรมการซื้อขาย เช่น รหัสลูกค้า
                        รายละเอียดการสั่งซื้อ (อาทิ สินค้าที่ต้องการ จำนวน
                        และคุณภาพ) และรายละเอียดการร้องเรียนเกี่ยวกับสินค้า
                        (อาทิ วันที่สั่งซื้อสินค้า ประเภทสินค้า
                        สาขาที่เกี่ยวข้อง และปัญหาที่พบ) เป็นต้น
                      </p>
                      <p>
                        4.5 ข้อมูลเกี่ยวกับการชำระเงิน เช่น จำนวนเงิน
                        วงเงินสินเชื่อ เงื่อนไขการชำระเงิน เลขที่บัญชี
                        ข้อมูลส่วนบุคคลที่ปรากฏในใบแจ้งหนี้ ใบกำกับภาษี
                        ใบเสร็จรับเงิน ใบสำคัญรับเงิน และใบหักบัญชีเงินฝาก
                        เป็นต้น
                      </p>
                      <p>
                        4.6 ข้อมูลเกี่ยวกับความสนใจและพฤติกรรม เช่น
                        พฤติกรรมหรือแนวโน้มในการซื้อสินค้าและ/หรือบริการ
                        ความสนใจในเนื้อหาที่ชื่นชอบบนสื่อสังคมออนไลน์ เว็บไซต์
                        หรือช่องทางประชาสัมพันธ์ต่างๆ ของบริษัทฯ เป็นต้น
                      </p>
                      <p>
                        4.7
                        ข้อมูลที่ใช้ประกอบเป็นหลักฐานในการลงทะเบียนเป็นลูกค้ากับบริษัทฯ
                        หรือในการทำธุรกรรมต่าง ๆ เช่น
                        ข้อมูลส่วนบุคคลที่ปรากฏในสำเนาบัตรประชาชน
                        สำเนาหนังสือเดินทาง สำเนาทะเบียนบ้าน
                        สำเนาใบเปลี่ยนชื่อนามสกุล
                        สำเนาใบอนุญาตประกอบกิจการโรงงาน แบบฟอร์มลงทะเบียน
                        สำเนาเอกสารสิทธิที่ดิน ใบขอเปิดหน้าบัญชีลูกค้า
                        เอกสารรับรองบุคคลที่ไม่เกี่ยวโยงกัน หนังสือมอบอำนาจ
                        สำเนาหนังสือรับรองบริษัท สำเนาภพ.09/20 แผนที่
                        เอกสารหลักประกัน (เช่น โฉนดที่ดิน
                        หนังสือค้ำประกันโดยธนาคาร (Bank Guarantee)
                        และหนังสือค้ำประกันโดยบุคคล)
                        สัญญาซื้อขายหรือสัญญาอื่นใดที่เกี่ยวข้องกับธุรกรรม
                        และใบส่งสินค้า เป็นต้น
                      </p>
                      <p>
                        4.8 ข้อมูลด้านเทคนิค เช่น ข้อมูลจราจรทางคอมพิวเตอร์
                        (Log) หมายเลขประจำเครื่องคอมพิวเตอร์ (IP Address)
                        ข้อมูลพฤติกรรมการใช้งานเว็บไซต์ที่บริษัทฯ
                        ได้เก็บรวบรวมผ่านคุกกี้
                        (Cookies)และข้อมูลระบุตำแหน่งที่สร้างโดย Global
                        Positioning System (GPS)
                        หรือเทคโนโลยีอื่นที่คล้ายคลึงกัน เป็นต้น
                      </p>
                      <p>
                        4.9 ข้อมูลอื่น ๆ เช่น บันทึกเสียงการสนทนา
                        และบันทึกภาพนิ่งและภาพเคลื่อนไหวผ่านกล้องวงจรปิด (CCTV)
                        เป็นต้น
                      </p>
                      <p>
                        5.
                        วัตถุประสงค์และฐานทางกฎหมายในการประมวลผลข้อมูลส่วนบุคคล
                      </p>
                      <p>
                        5.1 บริษัทฯ ประมวลผลข้อมูลส่วนบุคคลของท่าน
                        เพื่อวัตถุประสงค์ต่าง ๆ ภายใต้ฐานทางกฎหมาย ดังต่อไปนี้
                        (รวมกันเรียกว่า “วัตถุประสงค์ที่กำหนด”)
                      </p>
                      <p>
                        (ก)
                        เพื่อวัตถุประสงค์ในการวิเคราะห์ข้อมูลและสำรวจพฤติกรรมของลูกค้า
                        หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                      </p>
                      <p>
                        <li>
                          ฐานความยินยอม: การที่บริษัทฯ
                          เป็นฝ่ายเข้าขอสัมภาษณ์และสังเกตพฤติกรรมของลูกค้า
                          เพื่อขอเก็บรวบรวมข้อมูลส่วนบุคคล
                          ที่ลูกค้าได้ให้ไว้กับทางบริษัทฯ ผ่านช่องทางต่างๆ
                          รวมถึงการซื้อข้อมูลส่วนบุคคลของลูกค้ามาจากบุคคลภายนอก
                          เพื่อนำมาวิเคราะห์ข้อมูลและสำรวจพฤติกรรมของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน บริษัทฯ
                          จะดำเนินการโดยอาศัยความยินยอมที่ได้รับจากลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                        </li>
                      </p>
                      <p>
                        (ข)
                        เพื่อวัตถุประสงค์ในการคัดเลือกผู้เข้าร่วมประมูลการขายสินค้าของบริษัทฯ
                        และการบริหารจัดการใด ๆ
                        ที่เกี่ยวข้องกับการประมูลขายสินค้า
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลของผู้เข้าร่วมการประมูลหรือผู้ที่เกี่ยวข้องกับผู้ที่เข้าร่วมการประมูล
                          (ในกรณีที่ผู้เข้าร่วมประมูลเป็นนิติบุคคล)
                          เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายในการบริหารจัดการประมูลขายสินค้าของบริษัทฯ
                          และกระบวนการที่เกี่ยวข้องต่าง ๆ เช่น
                          การพิจารณาคุณสมบัติผู้เข้าร่วมประมูล การติดต่อสื่อสาร
                          การสรุปผลการประมูล
                          และการรวบรวมหลักฐานที่เกี่ยวข้องกับผู้ชนะการประมูล
                          เป็นต้น
                        </li>
                      </p>
                      <p>
                        ฐานสัญญา:
                        การประมวลผลข้อมูลส่วนบุคคลของผู้เข้าร่วมการประมูลเป็นการจำเป็นเพื่อการเข้าสัญญากับผู้เข้าร่วมประมูล
                        หากผู้เข้าร่วมประมูลชนะการประมูล
                        ตลอดจนการซื้อขายสินค้ากับผู้ที่ชนะการประมูล เป็นต้น
                      </p>
                      <p>
                        (ค)
                        เพื่อวัตถุประสงค์ในการลงทะเบียนการสมัครสมาชิกหรือการลงทะเบียนเพื่อเปิดบัญชีลูกค้า
                        หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                      </p>
                      <p>
                        <li>
                          ฐานสัญญา: การประมวลผลข้อมูลส่วนบุคคลของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อใช้ในการดำเนินการตามคำขอของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          สำหรับการลงทะเบียนสมัครสมาชิกเป็นลูกค้ารายใหม่หรือการเปิดบัญชีลูกค้า
                          ผ่านทางเว็บไซต์หรือช่องทางการให้บริการต่าง ๆ
                          ของบริษัทฯ
                        </li>
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย: ในกรณีที่ลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกันเป็นนิติบุคคล
                          การประมวลผลข้อมูลส่วนบุคคลของผู้ที่เกี่ยวข้องกับลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายในการดำเนินธุรกิจของบริษัทฯ
                          เช่น การจัดการบัญชี และการตรวจสอบยืนยันตัวตนของลูกค้า
                          เป็นต้น
                        </li>
                      </p>
                      <p>
                        <li>
                          ฐานความยินยอม:
                          การเก็บรวบรวมข้อมูลส่วนบุคคลที่มีความอ่อนไหวที่ปรากฏบนบัตรประจำตัวของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน เช่น ศาสนา
                          และหมู่โลหิต
                          จะกระทำโดยอาศัยความยินยอมที่ได้รับจากลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                        </li>
                      </p>
                      <p>
                        กรณีที่ลูกค้า
                        หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกันไม่ประสงค์ให้ความยินยอม
                        บริษัทฯ ขอสงวนสิทธิ์ดำเนินการใด ๆ ที่จำเป็น
                        เพื่อลบหรือทำให้ข้อมูลส่วนบุคคลที่มีความอ่อนไหวไม่ถูกแสดง
                        ก่อนการเก็บข้อมูลส่วนบุคคลอื่น ๆ
                        ที่ปรากฏบนบัตรประจำตัวของลูกค้า
                        หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                      </p>
                      <p>
                        (ง) เพื่อวัตถุประสงค์ในการดำเนินการเข้าทำสัญญา
                        และการวางหลักประกัน
                      </p>
                      <p>
                        <li>
                          ฐานสัญญา: การประมวลผลข้อมูลส่วนบุคคลของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อการเข้าทำสัญญา และกระบวนการอื่น ๆ
                          ที่เกี่ยวข้องกับการเข้าทำสัญญา เช่น
                          การตรวจสอบหลักฐานประกอบการทำสัญญา
                          การพิจารณาวงเงินสินเชื่อ และการวางหลักประกัน เป็นต้น
                        </li>
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย: ในกรณีที่ลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกันเป็นนิติบุคคล
                          การประมวลผลข้อมูลส่วนบุคคลของผู้ที่เกี่ยวข้องกับลูกค้าหรือของบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายในการดำเนินธุรกิจของบริษัทฯ
                          เช่น การบริหารจัดการสัญญา
                          การตรวจสอบยืนยันตัวตนของลูกค้า
                          และการพิจารณาคุณสมบัติลูกค้า เป็นต้น
                        </li>
                      </p>
                      <p>
                        (จ)
                        เพื่อวัตถุประสงค์ในการบริหารจัดการคำสั่งซื้อจากลูกค้า
                        หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                      </p>
                      <p>
                        <li>
                          ฐานสัญญา: การประมวลผลข้อมูลส่วนบุคคลของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อปฏิบัติหน้าที่ตามสัญญาซื้อขายและบริการ
                          ซึ่งลูกค้าหรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกันเป็นคู่สัญญา
                          เช่น การสั่งซื้อผ่านช่องทางใด ๆ
                          ไม่ว่าจะเป็นช่องทางของบริษัทฯ โดยตรง
                          หรือช่องทางของบุคคลที่สาม อาทิ ณ จุดขาย ผ่านเว็บไซต์
                          หรือแอปพลิเคชันหรือผ่านศูนย์บริการข้อมูลลูกค้า (Call
                          Center) หรือช่องทางอื่น ๆ เป็นต้น
                        </li>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย: ในกรณีที่ลูกค้า
                          หรือบุคคลอื่นในลักษณะคล้ายคลึงกันเป็นนิติบุคคล
                          การประมวลผลข้อมูลส่วนบุคคลของผู้ที่เกี่ยวข้องกับลูกค้าหรือของบุคคลอื่นในลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายในการดำเนินธุรกิจของบริษัทฯ
                          เช่น การบริหารจัดการคำสั่งซื้อจากลูกค้า
                          และการตรวจสอบยืนยันตัวตนของลูกค้า เป็นต้น
                        </li>
                      </p>
                      <p>
                        (ฉ) เพื่อวัตถุประสงค์ในการจัดเตรียมสินค้าและ/หรือบริการ
                        และดำเนินการอื่นใดที่เกี่ยวข้อง
                      </p>
                      <p>
                        <li>
                          ฐานสัญญา: การประมวลผลข้อมูลส่วนบุคคลของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อปฏิบัติตามสัญญาซึ่งลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกันเป็นคู่สัญญา
                          เช่น การจัดส่งสินค้า การวางบิล
                          การยืนยันยอดหนี้ค้างชำระ
                          และการจัดส่งใบเสร็จรับเงินให้แก่ลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน เป็นต้น
                        </li>
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย: ในกรณีที่ลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกันเป็นนิติบุคคล
                          การประมวลผลข้อมูลส่วนบุคคลของผู้ที่เกี่ยวข้องกับลูกค้าหรือของบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายในการดำเนินธุรกิจของบริษัทฯ
                          เช่น การติดต่อสื่อสารกับลูกค้า เป็นต้น
                        </li>
                      </p>
                      <p>
                        (ช)
                        เพื่อวัตถุประสงค์ในการดำเนินการแก้ไขเปลี่ยนแปลงรายละเอียดเกี่ยวกับลูกค้าในระบบของบริษัทฯ
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลของลูกค้า
                          ผู้ที่เกี่ยวข้องกับลูกค้าหรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายในการอำนวยความสะดวกแก่ลูกค้า
                          ผู้ที่เกี่ยวข้องกับลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          ในการแก้ไขเปลี่ยนแปลงรายละเอียดเกี่ยวกับลูกค้าหรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          ในระบบของบริษัทฯ ให้มีความถูกต้อง เป็นปัจจุบัน สมบูรณ์
                          และไม่ก่อให้เกิดความเข้าใจผิด
                        </li>
                      </p>
                      <p>
                        (ซ) เพื่อวัตถุประสงค์ในการรับเรื่องร้องเรียนจากลูกค้า
                        ผู้ที่เกี่ยวข้องกับลูกค้า
                        หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน{" "}
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายในการอำนวยความสะดวกแก่ลูกค้า
                          ผู้ที่เกี่ยวข้องกับลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน ในการร้องเรียน
                          หรือยื่นข้อเสนอแนะจากลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เพื่อเป็นการพัฒนาคุณภาพการให้บริการของบริษัทฯ
                        </li>
                      </p>
                      <p>
                        (ฌ)
                        เพื่อวัตถุประสงค์ในการประชาสัมพันธ์และเพื่อกิจกรรมทางการตลาดของบริษัทฯ{" "}
                      </p>
                      <p>
                        <li>
                          ฐานความยินยอม: การที่บริษัทฯ
                          จะดำเนินกิจกรรมทางการตลาดใด ๆ อาทิ
                          การส่งข้อความเกี่ยวกับการสื่อสารทางการตลาดให้แก่ลูกค้า
                          การขอถ่ายภาพนิ่งหรือภาพเคลื่อนไหวของลูกค้า
                          เพื่อนำไปประมวลภาพและประชาสัมพันธ์กิจกรรมการตลาดตามช่องทางสื่อต่าง
                          ๆ บริษัทฯ
                          จะดำเนินการโดยอาศัยความยินยอมที่ได้รับจากท่าน
                        </li>
                      </p>
                      <p>(ญ) เพื่อวัตถุประสงค์ในด้านการจัดทำฐานระบบสมาชิก</p>
                      <p>
                        <li>
                          ฐานสัญญา: การประมวลผลข้อมูลส่วนบุคคลของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                          เป็นการจำเป็นเพื่อใช้ในการดำเนินการตามคำขอของลูกค้า
                          หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกันที่เป็นสมาชิก
                        </li>
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลของท่านจากการใช้ระบบคะแนน
                          (Royalty Reward) บนเว็บไซต์ แอปพลิเคชัน
                          หรือช่องทางอื่น ๆ
                          เป็นการจำเป็นเพื่อประโยชน์ชอบด้วยกฎหมายของบริษัทฯ
                          ในการบันทึกการสะสมและ/หรือแลกเปลี่ยนคะแนนซึ่งเป็นไปตามเงื่อนไขของบริษัทฯ
                          รวมไปถึงการจัดอันดับลูกค้าที่เป็นสมาชิก (Ranking)
                        </li>
                      </p>
                      <p>
                        (ฎ) เพื่อวัตถุประสงค์ในการวิเคราะห์การใช้งานเว็บไซต์
                        แอปพลิเคชัน หรือช่องทางอื่น ๆ ของท่าน
                        เพื่อพัฒนาคุณภาพการให้บริการของบริษัทฯ
                        หรือเพื่อวัตถุประสงค์ทางการตลาด
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลของท่านจากการใช้งานเว็บไซต์
                          แอปพลิเคชัน หรือช่องทางอื่น ๆ
                          เป็นการจำเป็นเพื่อประโยชน์ชอบด้วยกฎหมายของบริษัทฯ
                          ในการดำเนินธุรกิจและการพัฒนาคุณภาพการให้บริการของบริษัทฯ
                          เช่น การปรับปรุงเว็บไซต์ แอปพลิเคชัน หรือช่องทางอื่น ๆ
                          การพัฒนาคุณภาพสินค้าและบริการ
                          ตลอดจนการแก้ไขข้อบกพร่องและปัญหาต่าง ๆ
                          ที่เกี่ยวข้องกับสินค้าและบริการ
                        </li>
                      </p>
                      <p>
                        <li>
                          ฐานความยินยอม: ในกรณีที่บริษัทฯ
                          เก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อนำมาวิเคราะห์พฤติกรรมและทำโฆษณาแบบเจาะจงตามพฤติกรรมของท่าน
                          (Personalized Marketing)
                          หรือในกรณีที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนดให้ต้องได้รับความยินยอมจากท่าน
                          บริษัทฯ จะดำเนินการโดยอาศัยความยินยอมที่ได้รับจากท่าน
                        </li>
                      </p>
                      <p>
                        (ฏ) เพื่อวัตถุประสงค์ในการติดต่อสื่อสาร กับลูกค้า
                        หรือบุคคลอื่นใดที่มีลักษณะคล้ายคลึงกัน
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลเป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมาย
                          ในการติดต่อสื่อสารกับท่าน เกี่ยวกับการให้บริการต่าง ๆ
                          ของบริษัทฯ เช่น การดำเนินการตามคำขอใช้บริการ
                          การตอบข้อซักถาม หรือข้อเสนอแนะของท่าน เป็นต้น
                        </li>
                      </p>
                      <p>
                        (ฐ)
                        เพื่อวัตถุประสงค์ในการปฏิบัติตามกฎหมายที่เกี่ยวข้องกับธุรกิจของบริษัทฯ
                        และคำสั่งโดยชอบด้วยกฎหมายของหน่วยงานของรัฐและเจ้าหน้าที่ที่เกี่ยวข้อง
                      </p>
                      <p>
                        <li>
                          ฐานกฎหมาย:
                          เพื่อการปฏิบัติตามกฎหมายซึ่งบังคับใช้กับบริษัทฯ อาทิ
                          กฎหมายว่าด้วยภาษีอากร
                          กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
                          และคำสั่งโดยชอบด้วยกฎหมายของหน่วยงานของรัฐและเจ้าหน้าที่ที่เกี่ยวข้อง
                          เช่น สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล เป็นต้น
                        </li>
                      </p>
                      <p>
                        (ฑ) เพื่อวัตถุประสงค์ในการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
                        การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย
                        หรือการยกขึ้นต่อสู้ซึ่งสิทธิเรียกร้องของบริษัทฯ
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลเป็นการจำเป็นเพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
                          การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย
                          หรือการยกขึ้นต่อสู้ซึ่งสิทธิเรียกร้องของบริษัทฯ
                          ในขั้นตอนต่าง ๆ ตามกฎหมาย เช่น
                          การสอบสวนและ/หรือการไต่สวนโดยเจ้าหน้าที่รัฐ
                          การเตรียมคดี การดำเนินคดี
                          และ/หรือการต่อสู้คดีในชั้นศาล เป็นต้น
                        </li>
                      </p>
                      <p>
                        (ฒ)
                        เพื่อวัตถุประสงค์ในการตรวจสอบดูแลความสงบเรียบร้อยและรักษาความปลอดภัยของบุคคล
                        และ ทรัพย์สินของบริษัทฯ
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลเป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมาย
                          ในการตรวจสอบดูแลความสงบเรียบร้อยและรักษาความปลอดภัยในทรัพย์สินของบริษัทฯ
                          หรือการใช้ภาพบันทึกจากกล้องวงจรปิด (CCTV)
                          เพื่อป้องกันการสูญหาย หรือเสียหายในทรัพย์สินของบริษัทฯ
                          หรือใช้เพื่อติดตามเอาคืนทรัพย์สิน
                          หรือเรียกให้ชดใช้ค่าเสียหาย
                          ในกรณีที่มีการทำให้ทรัพย์สินของบริษัทฯ สูญหาย
                          หรือเสียหาย เป็นต้น
                        </li>
                      </p>
                      <p>
                        <li>
                          ฐานการป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
                          หรือสุขภาพของบุคคล:
                          การประมวลผลข้อมูลส่วนบุคคลเป็นการจำเป็นเพื่อประโยชน์ในการตรวจสอบดูแล
                          ป้องกัน หรือระงับเหตุการณ์ใด ๆ
                          ที่อาจเป็นอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
                        </li>
                      </p>
                      <p>
                        (ณ) เพื่อการดำเนินการใด ๆ
                        ที่จำเป็นและเป็นประโยชน์ต่อท่าน
                        หรือเกี่ยวข้องโดยตรงกับวัตถุประสงค์ที่กำหนดใด ๆ ข้างต้น
                      </p>
                      <p>
                        <li>
                          ฐานประโยชน์โดยชอบด้วยกฎหมาย:
                          การประมวลผลข้อมูลส่วนบุคคลเป็นการจำเป็นเพื่อประโยชน์ชอบด้วยกฎหมายของบริษัทฯ
                          ในการดำเนินการใด ๆ ที่จำเป็นของบริษัทฯ
                          และ/หรือเป็นประโยชน์ต่อท่าน
                          หรือเกี่ยวข้องโดยตรงกับวัตถุประสงค์ที่กำหนดข้างต้น
                        </li>
                      </p>
                      <p>
                        ทั้งนี้
                        หากเป็นกรณีที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนดให้การประมวลผลข้อมูลส่วนบุคคลใดต้องได้รับความยินยอมจากท่าน
                        บริษัทฯ จะขอความยินยอมโดยชัดแจ้งจากท่าน
                      </p>
                      <p>
                        (ด) เพื่อวัตถุประสงค์อื่น ๆ ที่บริษัทฯ จะแจ้งให้ท่านทราบ{" "}
                      </p>
                      <p>
                        บริษัทฯ
                        จะแจ้งให้ท่านทราบถึงวัตถุประสงค์อื่นใดอันเป็นเหตุให้บริษัทฯ​
                        ต้องประมวลผลข้อมูลส่วนบุคคลของท่านนอกเหนือไปจากวัตถุประสงค์ตามที่ระบุไว้ข้างต้น
                        หรือเมื่อบริษัทฯ
                        มีการเปลี่ยนแปลงวัตถุประสงค์เดิมที่กำหนดไว้
                      </p>
                      <p>
                        ทั้งนี้
                        หากเป็นกรณีที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนดให้การประมวลผลข้อมูลส่วนบุคคลใดต้องได้รับความยินยอมจากท่าน
                        บริษัทฯ จะขอความยินยอมโดยชัดแจ้งจากท่าน
                      </p>
                      <p>
                        5.2 เนื่องจากข้อมูลส่วนบุคคลของท่านที่บริษัทฯ
                        จะดำเนินการประมวลผลเพื่อวัตถุประสงค์ที่กำหนดตามข้อ 5.1
                        ข้างต้นในส่วนที่มีความเกี่ยวเนื่องกับการปฏิบัติตามกฎหมายหรือสัญญาหรือมีความจำเป็นเพื่อเข้าทำสัญญากับท่าน
                        เป็นข้อมูลที่จำเป็นต่อการบรรลุวัตถุประสงค์ดังกล่าว
                        หากท่านไม่ประสงค์ที่จะให้ข้อมูลส่วนบุคคลดังกล่าวแก่บริษัทฯ
                        อาจมีผลกระทบทางกฎหมาย หรืออาจทำให้บริษัทฯ
                        ไม่สามารถปฏิบัติหน้าที่ภายใต้สัญญาที่ได้เข้าทำกับท่าน
                        หรือไม่สามารถเข้าทำสัญญากับท่านได้ (แล้วแต่กรณี)
                        ในกรณีดังกล่าว บริษัทฯ
                        อาจมีความจำเป็นต้องปฏิเสธการเข้าทำสัญญากับท่าน
                        หรือยกเลิกการซื้อขายหรือการให้บริการที่เกี่ยวข้องกับท่าน
                        ไม่ว่าทั้งหมดหรือบางส่วน
                      </p>
                      <p>
                        5.3 ในกรณีที่บริษัทฯ
                        จะดำเนินการประมวลผลข้อมูลส่วนบุคคลของท่านในลักษณะ
                        และ/หรือเพื่อวัตถุประสงค์ที่ไม่สอดคล้องกับวัตถุประสงค์ที่ระบุไว้ข้างต้น
                        บริษัทฯ
                        จะจัดให้มีนโยบายหรือประกาศเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลเพิ่มเติม
                        และ/หรือมีหนังสือไปยังท่านเพื่ออธิบายการประมวลผลข้อมูลในลักษณะดังกล่าว
                        โดยท่านควรอ่านนโยบายหรือประกาศเพิ่มเติมที่เกี่ยวข้องร่วมกับประกาศฯ
                        ฉบับนี้ และ/หรือหนังสือดังกล่าว (แล้วแต่กรณี)
                      </p>
                      <p>6. การเปิดเผยข้อมูลส่วนบุคคล</p>
                      <p>
                        6.1 บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่าน
                        ภายใต้วัตถุประสงค์ที่กำหนดและตามหลักเกณฑ์ที่กฎหมายกำหนด
                        ให้แก่บุคคลและหน่วยงานดังต่อไปนี้
                      </p>
                      <p>
                        (ก) บริษัทในบริษัท ไนน์ เวลธิ จำกัด ทั้งนี้
                        ให้หมายความรวมถึงผู้บริหาร กรรมการ พนักงาน ลูกจ้าง
                        และ/หรือบุคลากรภายในของบริษัทดังกล่าวเท่าที่เกี่ยวข้อง
                        และตามความจำเป็นเพื่อการประมวลผลข้อมูลส่วนบุคคลของท่าน
                      </p>
                      <p>
                        (ข) คู่ค้าทางธุรกิจ ผู้ให้บริการ
                        และผู้ประมวลผลข้อมูลส่วนบุคคลที่บริษัทฯ
                        มอบหมายหรือว่าจ้างให้ทำหน้าที่บริหารจัดการ/ประมวลผลข้อมูลส่วนบุคคลให้แก่บริษัทฯ
                        ในการให้บริการต่าง ๆ เช่น
                        การให้บริการด้านเทคโนโลยีสารสนเทศ บริการเก็บบันทึกข้อมูล
                        บริการชำระเงิน บริการรับส่งไปรษณีย์ บริการรับส่งพัสดุ
                        บริการจัดพิมพ์ บริการวิเคราะห์ข้อมูล บริการทำการวิจัย
                        บริการทำการตลาด หรือบริการอื่น
                        ใดที่อาจเป็นประโยชน์ต่อท่าน
                        หรือเกี่ยวข้องกับการดำเนินธุรกิจของบริษัทฯ
                      </p>
                      <p>
                        (ค) ที่ปรึกษาของบริษัทฯ อาทิ ที่ปรึกษากฎหมาย ทนายความ
                        ผู้ตรวจสอบบัญชี หรือผู้เชี่ยวชาญอื่นใด
                        ทั้งภายในและภายนอกของบริษัทฯ
                      </p>
                      <p>
                        (ง) หน่วยงานของรัฐที่มีหน้าที่กำกับดูแลตามกฎหมาย
                        หรือที่ร้องขอให้เปิดเผยข้อมูลส่วนบุคคลโดยอาศัยอำนาจตามกฎหมาย
                        หรือที่เกี่ยวข้องกับกระบวนการทางกฎหมาย
                        หรือที่ได้รับอนุญาตตามกฎหมายที่เกี่ยวข้อง อาทิ
                        กรมสรรพากร กรมโรงงานอุตสาหกรรม กระทรวงพาณิชย์
                        สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
                        สำนักงานคณะกรรมการการแข่งขันทางการค้า
                        สำนักงานตำรวจแห่งชาติ สำนักงานอัยการสูงสุด และศาล
                        เป็นต้น
                      </p>
                      <p>
                        (จ)
                        บุคคลหรือหน่วยงานอื่นใดที่ท่านให้ความยินยอมให้เปิดเผยข้อมูลส่วนบุคคลของท่านต่อบุคคลหรือหน่วยงานนั้น
                        ๆ อาทิ การเปิดเผยการประมวลภาพกิจกรรมตามช่องทางสื่อต่าง ๆ
                        ของบริษัทฯ​ ให้แก่ประชาชนทั่วไป
                      </p>
                      <p>
                        6.2 การเปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลอื่น
                        จะดำเนินการภายใต้วัตถุประสงค์ที่กำหนด
                        หรือวัตถุประสงค์อื่นที่กฎหมายกำหนดให้กระทำได้เท่านั้น
                        ในกรณีที่กฎหมายกำหนดว่าต้องได้รับความยินยอมจากท่าน
                        บริษัทฯ จะขอความยินยอมจากท่านก่อน
                      </p>
                      <p>
                        6.3 ในการเปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลอื่น
                        บริษัทฯ
                        จะจัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองข้อมูลส่วนบุคคลที่ได้เปิดเผยและเพื่อปฏิบัติตามมาตรฐานและหน้าที่การคุ้มครองข้อมูลส่วนบุคคล
                        ตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                        ทั้งนี้ ในกรณีที่บริษัทฯ
                        ส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปต่างประเทศ บริษัทฯ
                        จะดำเนินการเพื่อทำให้แน่ใจว่าประเทศปลายทาง
                        องค์การระหว่างประเทศ
                        หรือผู้รับข้อมูลในต่างประเทศนั้นมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอหรือเพื่อทำให้แน่ใจว่าการส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปต่างประเทศเป็นไปตามหลักเกณฑ์ที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                        โดยในบางกรณี บริษัทฯ
                        อาจขอความยินยอมของท่านสำหรับการส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศดังกล่าว
                      </p>
                      <p>7. ระยะเวลาเก็บรักษาข้อมูลส่วนบุคคล</p>
                      <p>
                        บริษัทฯ
                        จะเก็บรักษาข้อมูลส่วนบุคคลของท่านตามระยะเวลาที่จำเป็นเพื่อบรรลุวัตถุประสงค์ที่กำหนดในการประมวลผลข้อมูลส่วนบุคคลนั้น
                        ๆ
                        โดยระยะเวลาเก็บรักษาข้อมูลส่วนบุคคลจะเปลี่ยนแปลงไปโดยขึ้นอยู่กับวัตถุประสงค์ที่กำหนดในการประมวลผลข้อมูลส่วนบุคคลนั้น
                        ๆ นอกจากนี้ บริษัทฯ
                        จะเก็บรักษาข้อมูลส่วนบุคคลเป็นระยะเวลาตามที่กฎหมายที่เกี่ยวข้องกำหนด
                        (ถ้ามี)
                        โดยคำนึงถึงอายุความตามกฎหมายสำหรับการดำเนินคดีที่อาจเกิดขึ้นจากหรือเกี่ยวข้องกับเอกสารหรือข้อมูลส่วนบุคคลที่บริษัทฯ
                        เก็บรวบรวมไว้ในแต่ละรายการ
                        และโดยคำนึงถึงแนวปฏิบัติของบริษัทฯ
                        และของภาคธุรกิจที่เกี่ยวข้องสำหรับข้อมูลส่วนบุคคลแต่ละประเภทเป็นสำคัญ
                      </p>
                      <p>
                        ทั้งนี้ บริษัทฯ
                        จะเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาไม่เกิน 10
                        ปี นับแต่วันที่นิติสัมพันธ์ระหว่างท่านกับบริษัทฯ
                        สิ้นสุดลง อย่างไรก็ดี บริษัทฯ
                        อาจเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาเกินกว่าระยะเวลาดังกล่าวหากกฎหมายอนุญาตหรือการเก็บรักษาข้อมูลส่วนบุคคลดังกล่าวจำเป็นต่อการก่อตั้งสิทธิเรียกร้องทางกฎหมายของบริษัทฯ
                      </p>
                      <p>
                        หลังจากครบกำหนดระยะเวลาดังกล่าวข้างต้น บริษัทฯ
                        จะลบหรือทำลายข้อมูลส่วนบุคคลดังกล่าว
                        จากการจัดเก็บหรือระบบของบริษัทฯ
                        และของบุคคลอื่นซึ่งให้บริการแก่บริษัทฯ (ถ้ามี)
                        หรือทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวท่านได้
                        เว้นแต่จะเป็นกรณีที่บริษัทฯ
                        สามารถเก็บรักษาข้อมูลส่วนบุคคลดังกล่าวได้ต่อไปตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลหรือกฎหมายอื่นที่เกี่ยวข้องกำหนด
                        ทั้งนี้
                        สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับระยะเวลาเก็บรักษาข้อมูลส่วนบุคคลของท่าน
                        ท่านสามารถติดต่อมายังบริษัทฯ
                        ตามรายละเอียดการติดต่อที่ระบุไว้ในข้อ 9 ของประกาศฯ
                        ฉบับนี้
                      </p>
                      <p>8. สิทธิต่าง ๆ ของท่านเกี่ยวกับข้อมูลส่วนบุคคล</p>
                      <p>
                        ในฐานะเจ้าของข้อมูลส่วนบุคคล ท่านมีสิทธิต่าง ๆ
                        ที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่านดังต่อไปนี้
                        ภายใต้หลักเกณฑ์ วิธีการ
                        และเงื่อนไขตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
                        ทั้งนี้ หากท่านประสงค์ที่จะขอใช้สิทธิของท่าน
                        ท่านสามารถติดต่อมายังบริษัทฯ ตามรายละเอียดการติดต่อในข้อ
                        9 ของประกาศฯ ฉบับนี้
                      </p>
                      <p>8.1 สิทธิในการเข้าถึงข้อมูลส่วนบุคคล</p>
                      <p>
                        ท่านมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่านและขอให้บริษัทฯ
                        ทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่ท่าน ทั้งนี้
                        ตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                      </p>
                      <p>8.2 สิทธิในการโอนย้ายข้อมูลส่วนบุคคล</p>
                      <p>
                        ท่านมีสิทธิที่จะขอรับข้อมูลส่วนบุคคลเกี่ยวกับท่าน
                        รวมถึงมีสิทธิขอให้ส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น
                        หรือตัวท่านเอง เว้นแต่โดยสภาพไม่สามารถทำได้ ทั้งนี้
                        ตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                      </p>
                      <p>8.3 สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล</p>
                      <p>
                        ท่านมีสิทธิขอคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านได้ในกรณีที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                      </p>
                      <p>8.4 สิทธิในการลบข้อมูลส่วนบุคคล</p>
                      <p>
                        ท่านอาจขอให้บริษัทฯ ลบ
                        ทำลายหรือทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้ในกรณีที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                      </p>
                      <p>8.5 สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล</p>
                      <p>
                        ท่านมีสิทธิขอให้บริษัทฯ
                        ระงับการประมวลผลข้อมูลส่วนบุคคลของท่านในกรณีที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                      </p>
                      <p>8.6 สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง</p>
                      <p>
                        ท่านมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง
                        หากข้อมูลส่วนบุคคลของท่านนั้นไม่ถูกต้อง ไม่เป็นปัจจุบัน
                        ไม่ครบถ้วนสมบูรณ์ หรือก่อให้เกิดความเข้าใจผิด
                      </p>
                      <p>8.7 สิทธิที่จะเพิกถอนความยินยอม</p>
                      <p>
                        ในกรณีที่บริษัทฯ
                        อาศัยความยินยอมของท่านในการประมวลผลข้อมูลส่วนบุคคลฯ
                        ของท่าน
                        ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทฯ
                        ได้
                      </p>
                      <p>8.8 สิทธิในการยื่นข้อร้องเรียน</p>
                      <p>
                        หากท่านมีความกังวลหรือมีข้อสงสัยเกี่ยวกับแนวทางการปฏิบัติของบริษัทฯ
                        เกี่ยวกับข้อมูลส่วนบุคคลของท่าน โปรดติดต่อบริษัทฯ
                        โดยใช้รายละเอียดการติดต่อตามข้อ 9 ของประกาศฯ ฉบับนี้
                        ทั้งนี้ ในกรณีที่มีเหตุให้เชื่อได้ว่าบริษัทฯ
                        ได้ทำการฝ่าฝืนกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
                        ท่านมีสิทธิยื่นข้อร้องเรียนต่อคณะกรรมการผู้เชี่ยวชาญที่ได้รับการแต่งตั้งโดยคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลตามระเบียบและวิธีการตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                      </p>
                      <p>
                        ทั้งนี้ บริษัทฯ
                        ขอสงวนสิทธิในการพิจารณาคำร้องขอใช้สิทธิของท่านและดำเนินการตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                      </p>
                      <p>8. วิธีการติดต่อบริษัทฯ</p>
                      <p>
                        บริษัท ไนน์ เวลธิ จำกัด
                        เป็นผู้ประสานงานเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของบริษัทฯ
                        ในกรณีที่ท่านมีข้อสงสัยใด ๆ
                        หรือต้องการใช้สิทธิของท่านตามที่กำหนดไว้ในประกาศฯ นี้
                        ท่านสามารถติดต่อบริษัทฯ ผ่านช่องทาง ดังนี้
                      </p>
                      <p>บริษัท ไนน์ เวลธิ จำกัด</p>
                      <p>สถานที่ติดต่อ:</p>
                      <p>
                        379/157 ถนนกาญจนาภิเษก แขวงหลักสอง เขตบางแค
                        กรุงเทพมหานคร 10160
                      </p>
                      <p>10. การเปลี่ยนแปลงประกาศฯ ฉบับนี้</p>
                      <p>
                        {" "}
                        บริษัทฯ อาจทำการเปลี่ยนแปลงประกาศฯ ฉบับนี้เป็นครั้งคราว
                        เพื่อให้สอดคล้องกับการเปลี่ยนแปลงใด ๆ
                        ที่เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่าน
                        และตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลหรือกฎหมายอื่นที่เกี่ยวข้องกำหนด
                        โดยบริษัทฯ
                        จะแจ้งให้ท่านทราบถึงการแก้ไขเปลี่ยนแปลงประกาศฯ
                        ที่สำคัญใด ๆ พร้อมกับประกาศฯ ฉบับปรับปรุง
                        ผ่านช่องทางที่เหมาะสม ทั้งนี้ บริษัทฯ
                        ขอแนะนำให้ท่านตรวจสอบการเปลี่ยนแปลงประกาศฯ
                        ฉบับนี้เป็นระยะ ๆ{" "}
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </FormGroup>
                <Button
                  disabled={check == true ? false : true}
                  style={{
                    background: "#49C5B1",
                    marginTop: "15px",
                    border: "0px",
                  }}
                  //tag="input"
                  // type="submit"
                  value="Submit"
                  // href="/verification"
                  onClick={() => {
                    registerPost();
                  }}
                >
                  ลงทะเบียน
                </Button>
                <FormGroup align="center" style={{ marginTop: "25px" }}>
                  <Label>หากมีบัญชีอยู่แล้ว</Label>
                  <a
                    href="/"
                    className="text-decoration-none"
                    style={{ marginLeft: "5px", color: "#49C5B1" }}
                  >
                    ล็อกอิน
                  </a>
                </FormGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default RegisterPage;
